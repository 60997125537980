<template>
  <div class="input-search-coordinator">
    <a-input
      :value="modelValue"
      @input="updateValue"
      :placeholder="placeholder"
      class="bg-ems-gray700 border-none h-[38px] rounded-[41px]"
    >
      <template #prefix>
        <cds-feather-icons type="search" size="14" />
      </template>
    </a-input>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import VueTypes from 'vue-types';
const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  placeholder: VueTypes.string.def(''),
  modelValue: VueTypes.string.def(''),
});
const updateValue = (event) => {
  emit('update:modelValue', event.target.value);
};
</script>
<style lang="scss">
.input-search-coordinator .ant-input-affix-wrapper > input.ant-input {
  background: #2B2A3A;
  color: #FFFFFF;
}
.input-search-coordinator .ant-input-affix-wrapper > input.ant-input::placeholder {
  color: #F7F0F7;
}
</style>

<template>
  <div
    :class="`custom-tab-messages  bg-ems-gray800 h-[calc(100%)] px-[16px] ${
      isCollapse ? 'w-[100px]' : 'w-full'
    }`"
    class="overflow-hidden relative"
  >
    <ModalCall
      @handleJoinCall="handleJoinCall"
      :is-show-modal-confirm="isShowModalConfirm"
      @handleCancel="isShowModalConfirm = false"
      :list-actions="listActions"
      @handleSelectItem="handleSelectItem"
    />
    <HeaderTab
      :title="t('coordinator.walkie_messages')"
      :is-collapse="isCollapse"
      @handleCollapse="handleCollapse"
    />
    <div v-if="!isCollapse">
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-2">
          <img
            v-if="
              isShowMember === TAB_WALKIE.CHAT ||
              isShowMember === TAB_WALKIE.CALL ||
              isShowMember === TAB_WALKIE.SEND_MESSAGE
            "
            src="@/static/img/icon/CaretDown.svg"
            class="cursor-pointer w-[18px]"
            @click="handleBack"
          />
          <p
            class="text-ems-gray400 uppercase"
            v-if="itemValue && itemValue.otherInfo"
          >
            {{ itemValue.otherInfo.peerName || '' }}
          </p>
        </div>
        <div class="flex items-center justify-center space-x-[16px]">
          <div
            v-if="isShowMember !== TAB_WALKIE.CALL"
            class="h-[32px] min-w-[84px] bg-ems-main4 flex items-center justify-center rounded-[20px] cursor-pointer px-[16px]"
            @click="isShowModalConfirm = true"
          >
            <p class="font-semibold text-ems-gray400 text-[14px]">
              {{ t('coordinator.join') }}
            </p>
          </div>
          <div
            class="bg-ems-gray600 h-[32px] w-[32px] rounded-full flex items-center justify-center cursor-pointer"
          >
            <img src="@/static/img/icon/volumn_call.svg" alt="volumn_call" />
          </div>
          <div
            class="bg-ems-gray600 h-[32px] w-[32px] rounded-full flex items-center justify-center cursor-pointer"
            @click="handleSelectChat"
          >
            <img src="@/static/img/icon/chat_call.svg" alt="chat_call" />
          </div>
        </div>
      </div>
      <div class="mt-[10px]">
        <InputSearch v-model="userName" :placeholder="t('common.search')" />
      </div>

      <div
        v-if="isShowMember === TAB_WALKIE.WALKIE"
        :class="`h-[80vh] w-full flex px-[20px] py-[16px]  ${
          itemValue &&
          itemValue.participants &&
          itemValue.participants.length === 0 &&
          'justify-center items-center'
        }`"
      >
        <div
          v-if="
            itemValue &&
            itemValue.participants &&
            itemValue.participants.length > 0
          "
          class="grid grid-cols-2 h-max gap-[16px] w-full"
        >
          <div
            v-for="item in itemValue.participants"
            :key="item.id"
            class="h-[135px] w-full rounded-lg bg-ems-gray700 flex flex-col items-center justify-center space-y-[18px]"
            :class="`${item.microphone ? 'border-2 border-ems-main4' : ''}`"
          >
            <ItemUserCall :item="item" />
          </div>
        </div>
        <div
          v-else
          class="h-[210px] w-[210px] bg-ems-gray700 rounded-[24px] flex flex-col justify-center items-center space-y-[7px] p-[24px]"
        >
          <img src="@/static/img/icon/no_one.png" />
          <p class="text-white text-[12px] text-center leading-[18px]">
            {{ t('coordinator.no_one') }}
          </p>
        </div>
      </div>
      <TabMessage v-if="isShowMember === TAB_WALKIE.CHAT" />
      <TabWalkieTalkie
        v-if="isShowMember === TAB_WALKIE.CALL"
        @leave-room="handleLeaveRoom"
      />
      <ListSend v-else-if="isShowMember === TAB_WALKIE.SEND_MESSAGE" />
    </div>
  </div>
</template>
<script setup>
import { ref, computed, defineAsyncComponent, watch } from 'vue';
import { useStore } from 'vuex';
import ListSend from './tab/components/list-send.vue';
import { TAB_WALKIE, TAB_COORDINATION } from '@/util/common-constant.js';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import HeaderTab from '../header-tab.vue';
import ModalCall from './modal-call.vue';
import InputSearch from '../input-search.vue';
import ItemUserCall from './tab/components/item-user-call.vue';
const { t } = useI18n();
const { state, dispatch } = useStore();
const isShowMember = computed(() => state.coordinator.isShowMember || '');
const itemValue = computed(() => state.coordinator.itemValue || '');
const userInfo = computed(() => state.auth.userInfo || '');
const route = useRoute();
const isCollapse = ref(false);
const userName = ref('');
const isShowModalConfirm = ref(false);
const listActions = ref([
  {
    key: 'mic',
    icon: require('@/static/img/icon/mic_mini_call.svg'),
    isSelect: true,
  },
  {
    key: 'speak',
    icon: require('@/static/img/icon/volumn_mini_call.svg'),
    isSelect: true,
  },
  {
    key: 'video',
    icon: require('@/static/img/icon/video_close_mini_call.svg'),
    isSelect: true,
  },
]);
const TabWalkieTalkie = defineAsyncComponent(() =>
  import('./tab/tab-walkie-talkie.vue')
);
const TabMessage = defineAsyncComponent(() => import('./tab/tab-message.vue'));

const handleSelectItem = (key) => {
  const item = listActions.value.find((action) => action.key === key);
  if (item) {
    item.isSelect = !item.isSelect;
  }
};
const handleCollapse = (value) => {
  isCollapse.value = value;
};
const handleSelectChat = () => {
  dispatch('coordinator/handleLoadingHistoryMessage', true);
  dispatch('coordinator/viewMember', TAB_WALKIE.CHAT);
  dispatch('coordinator/getMessagesByDialogs', {
    dialogId: itemValue.value.dialogId,
    dialogType: itemValue.value.dialogType,
    isChated: true,
  });
};
watch(
  () => route.query,
  () => {
    isShowModalConfirm.value = false;
    dispatch('coordinator/viewMember', TAB_WALKIE.WALKIE);
  },
  {
    immediate: true,
    deep: true,
  }
);
const handleSendItemValue = (item, participants) => {
  let value = {
    ...item,
    participants: participants,
  };
  dispatch('coordinator/selectValue', value);
};
const handleLeaveRoom = async () => {
  let payload = {
    roomId: itemValue.value.dialogId,
  };
  await dispatch('coordinator/getInfoCall', payload).then((data) => {
    handleSendItemValue(itemValue.value, data.participants);
    localStorage.removeItem('action-call');
    handleBack();
  });
};
const handleBack = () => {
  dispatch('coordinator/viewMember', TAB_WALKIE.WALKIE);
};

const getSelectState = (key) => {
  const item = listActions.value.find((action) => action.key === key);
  return item ? item.isSelect : false;
};
const handleActionJoin = async (dialogId) => {
  dispatch('coordinator/viewMember', TAB_WALKIE.CALL);
  localStorage.setItem(
    'action-call',
    JSON.stringify({
      isCall: true,
      idCall: dialogId,
      user: userInfo.value.fullName,
    })
  );
  let value = {
    roomId: dialogId,
    camera: getSelectState('video'),
    microphone: getSelectState('mic'),
    speaker: getSelectState('speak'),
    screenShare: false,
  };
  dispatch('coordinator/selectValue', {
    ...itemValue.value,
    ...value,
    dialogId: dialogId,
  });
  dispatch('coordinator/joinRoom', value);
  isShowModalConfirm.value = false;
};
const handleJoinCall = async () => {
  if (itemValue.value.tab === TAB_COORDINATION.COORDINATION) {
    await handleActionJoin(itemValue.value.dialogId);
  } else {
    handleSendMessageConnect();
  }
};
const handleSendMessageConnect = async () => {
  let mess = {
    receiverId: itemValue.value.otherInfo.peerId,
    receiverType: itemValue.value.otherInfo.peerType,
    messageType: 'text',
    content: `${userInfo.value.fullName}`,
    forwarded: false,
  };
  let query = {
    id: itemValue.value.otherInfo.peerId,
    tab: TAB_COORDINATION.COORDINATION,
  };
  dispatch('coordinator/handleSetParams', query).then((data) => {});
  dispatch('coordinator/sendMessages', mess).then(async (data) => {
    let value = {
      ...itemValue.value,
      dialogId: data.dialogId,
      dialogType: data.dialogType,
    };
    dispatch('coordinator/selectValue', value);
    await handleActionJoin(data.dialogId);
  });
};
</script>
<style lang="scss">
.custom-tab-messages {
  transition: width 0.5s ease;
}
.custom-tab-messages .ant-tabs-nav-list {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #2B2A3A;
  margin-left: 20px;
  margin-right: 20px;
}
.custom-tab-messages .ant-tabs-tab {
  width: 200px;
  display: flex;
  justify-content: center;
  height: 100;
}
.custom-tab-messages .ant-input-affix-wrapper > input.ant-input {
  background: transparent !important;
}
</style>

<template>
  <div v-if="isCollapse" class="flex justify-center items-center pt-[20px]">
    <img
      src="@/static/img/icon/nav-expand.png"
      class="cursor-pointer"
      @click="handleClick(false)"
    />
  </div>
  <div
    v-if="!isCollapse"
    class="flex justify-between items-center h-[68px]"
  >
    <p class="text-ems-gray200 text-lg uppercase">
      {{ title}}
    </p>
    <img
      src="@/static/img/icon/nav-collapse.png"
      class="cursor-pointer"
      @click="handleClick(true)"
    />
  </div>
</template>

<script setup>
import { ref,defineProps } from 'vue';
import VueTypes from 'vue-types';
const emit = defineEmits(['handleCollapse']);
const props = defineProps({
  title: VueTypes.string.def(''),
  isCollapse: VueTypes.bool.def(false),
});
const handleClick = (value)=>{
    emit('handleCollapse',value);
}
</script>
<style lang="scss">

</style>

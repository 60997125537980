<template>
  <div
    :class="`custom-tab  bg-ems-gray800 h-full px-[16px] ${
      isCollapse ? 'w-[100px]' : 'w-full'
    } overflow-hidden`"
  >
    <HeaderTab
      :title="t('coordinator.list_coordinator')"
      :is-collapse="isCollapse"
      @handleCollapse="handleCollapse"
    />
    <div v-if="!isCollapse" class="w-full mt-[10px] h-[92vh] pb-5">
      <a-tabs
        v-if="!isCollapse"
        v-model:activeKey="activeTab"
        centered
        @change="handleChangeTab"
      >
        <a-tab-pane v-for="item in tabs" :key="item.key">
          <template #tab>
            <span>{{ item.tabName }} </span>
          </template>
        </a-tab-pane>
      </a-tabs>
      <Suspense>
        <template #default>
          <component :is="component" />
        </template>
        <template #fallback>
          <div class="spin-custom">
            <cds-loading />
          </div>
        </template>
      </Suspense>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, defineAsyncComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { TAB_COORDINATION } from '@/util/common-constant.js';
import { useStore } from 'vuex';
import HeaderTab from '../header-tab.vue';
const route = useRoute();
const { t } = useI18n();
const activeTab = ref(TAB_COORDINATION.COORDINATION);
const isCollapse = ref(false);
const router = useRouter();
const { dispatch } = useStore();
const TabCoordinator = defineAsyncComponent(() =>
  import('./tab-coordination.vue')
);
const TabPhoneBook = defineAsyncComponent(() =>
  import('./tab-phone.vue')
);
const TabChannel = defineAsyncComponent(() => import('./tab-channel.vue'));
const tabs = [
  {
    key: TAB_COORDINATION.COORDINATION,
    tabName: t('coordinator.coordinator'),
    component: TabCoordinator,
  },
  {
    key: TAB_COORDINATION.CONTACT,
    tabName: t('coordinator.contact'),
    component: TabPhoneBook,
  },
  {
    key: TAB_COORDINATION.CHANNEL,
    tabName: t('coordinator.channel'),
    component: TabChannel,
  },
];
const component = ref(tabs[0].component);
watch(
  () => route.query,
  () => {
    activeTab.value = route.query.tab;
    tabs.forEach((item) => {
      if (item.key === route.query.tab) {
        component.value = item.component;
      }
    });
  },
  {
    immediate: true,
    deep: true,
  }
);
const handleChangeTab = (value) => {
  let query = {
    tab: value,
  };
  dispatch('coordinator/handleSetParams', query);
};
const handleCollapse = (value) => {
  isCollapse.value = value;
};
</script>
<style lang="scss">
.custom-tab {
  transition: width 0.5s ease;
}
.custom-tab .ant-tabs-nav-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #2B2A3A;
  margin-left: 20px;
  margin-right: 20px;
}
.custom-tab .ant-tabs-tab {
  width: 140px;
  display: flex;
  justify-content: center;
}
</style>

<template>
  <div
    class="bg-ems-tag1 rounded-lg custom-dropdown overflow-auto hidden-scroll mt-[10px]"
    :style="{ height: `${windowHeight - 270}px` }"
  >
    <a-dropdown :trigger="['click']" class="w-[120px]">
      <div class="flex items-center px-[16px] space-x-2 pt-[10px]">
        <p class="text-white font-semibold" @click.prevent>
          {{ labelSeclect }}
        </p>
        <DownOutlined class="text-ems-gray500" />
      </div>
      <template #overlay>
        <div>
          <div
            v-for="(item, index) in menuItems"
            :key="index"
            @click="handleClick(item.type)"
            @mouseenter="hoveredItem = item.type"
            @mouseleave="hoveredItem = ''"
            :class="[
              hoveredItem === item.type || itemSelect === item.type
                ? 'bg-ems-gray700'
                : 'bg-ems-gray800',
              'flex cursor-pointer h-[39px] w-[120px] flex items-center',
            ]"
          >
            <p class="text-white cursor-pointer p-2">{{ item.label }}</p>
          </div>
        </div>
      </template>
    </a-dropdown>
    <div class="flex space-x-[16px] input-search-share  mt-[20px] px-[16px]">
      <a-input
        v-model:value="searchQuery"
        :placeholder="t('common.search')"
        class="bg-ems-gray700 h-[38px] rounded-lg"
      >
        <template #prefix>
          <cds-feather-icons type="search" size="14" />
        </template>
      </a-input>
      <button
        class="bg-ems-main2 py-1 px-3 rounded-[32px] text-white"
        @click="handleShareMess"
      >
        {{ t('common.send') }}
      </button>
    </div>
    <div
      ref="scrollContainer"
      class="mt-[24px] overflow-auto h-[75%] hidden-scroll"
    >
      <div
        v-if="itemSelect === 'channel'"
        v-for="(item, index) in listChannels"
        :key="index"
      >
        <div
          class="w-full h-[96px] mt-[11px] flex items-center px-[16px] rounded-lg relative cursor-pointer justify-between"
        >
          <div
            class="flex items-center space-x-[23px]"
          >
            <div
              class="rounded-full w-[64px] h-[64px] bg-ems-boTro2_500 relative"
            >
              <div
                class="rounded-full w-[64px] h-[64px] flex justify-center items-center overflow-hidden flex-shrink-0"
              >
                <p class="font-semibold text-3xl uppercase text-white">
                  {{ item.name && item.name.charAt(0) }}
                </p>
              </div>
              <div
                v-if="item.active"
                class="h-[16px] w-[16px] border-2 rounded-full border-ems-tag1 bg-ems-boTro4_600 absolute bottom-0 right-0"
              ></div>
            </div>
            <div class="space-y-[8px]">
              <p class="text-white font-semibold text-[16px]">
                {{ item.name }}
              </p>
              <div class="flex items-center space-x-[19px] justify-start">
                <p
                  class="text-white font-normal text-[14px] max-w-[200px] truncate py-2"
                >
                  {{ item.participantCount }}
                  {{ t('coordinator.member') }}
                </p>
                <p class="text-ems-gray200 font-normal text-[12px]">
                </p>
              </div>
            </div>
          </div>
          <div
            @click="handleClickChannle(item)"
            :class="`h-[28px] w-[28px] rounded-full select-none ${
              listSelectChannel.includes(item.channelId)
                ? 'bg-ems-main2'
                :'bg-gray-500'
            } flex justify-center items-center`"
          >
            <p class="text-[18px] text-white">
              {{ listSelectChannel.includes(item.channelId) ? '-' : '+' }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-else-if="itemSelect === 'contact'"
        v-for="(items, indexs) in listContactSend"
        :key="indexs"
      >
        <div
          class="w-full h-[96px] mt-[11px] flex items-center px-[16px] rounded-lg relative cursor-pointer justify-between"
        >
          <div class="flex items-center space-x-[23px]">
            <div class="rounded-full w-[64px] h-[64px] bg-white relative">
              <div
                class="rounded-full w-[64px] h-[64px] flex justify-center items-center overflow-hidden flex-shrink-0"
              >
                <img alt="avatar" :src="items.avatar" class="w-ful h-auto" />
              </div>
              <div
                v-if="items.userStatus.online"
                class="h-[16px] w-[16px] border-2 rounded-full border-ems-tag1 bg-ems-boTro4_600 absolute bottom-0 right-0"
              ></div>
            </div>
            <div class="space-y-[8px]">
              <p class="text-white font-semibold text-[16px]">
                {{ items.fullName }}
              </p>
              <div class="flex items-center space-x-[19px] justify-start">
                <p
                  class="text-white font-normal text-[14px] max-w-[200px] truncate py-2"
                >
                  {{
                    items.userStatus.online
                      ? t('coordinator.online')
                      : t('coordinator.offline')
                  }}
                </p>
                <p class="text-ems-gray200 font-normal text-[12px]">
                </p>
              </div>
            </div>
          </div>
          <div
            @click="handleClickContacts(items)"
            :class="`h-[28px] w-[28px] rounded-full select-none ${
              listSelectContact.includes(items.userId)
                ? 'bg-ems-main2'
                : 'bg-gray-500'
            } flex justify-center items-center`"
          >
            <p class="text-[18px] text-white">
              {{ listSelectContact.includes(items.userId) ? '-' : '+' }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useWindowHeight } from '@/util/common-utils';
import { DownOutlined } from '@ant-design/icons-vue';
const { t } = useI18n();
const { state, dispatch } = useStore();
const listContacts = computed(() => state.coordinator.listContacts);
const idSelect = ref('');
const searchQuery = ref('');
const hoveredItem = ref('');
const filteredListContactsFormat = ref([]);
const scrollContainer = ref(null);
const labelSeclect = ref(t('coordinator.channel'));
const itemSelect = ref('channel');
const { windowHeight } = useWindowHeight();
const listChannels = computed(() => state.coordinator.listChannelsInit);
const listContactSend = computed(() => state.coordinator.listContactSend);
const contentMessShare = computed(() => state.coordinator.contentMessShare);
const listSelectContact = ref([]);
const listSelectChannel = ref([]);
const itemValue = computed(() => state.coordinator.itemValue || '');
let arraySelect = [];
const menuItems = ref([
  { label: t('coordinator.channel'), type: 'channel' },
  { label: t('coordinator.contact'), type: 'contact' },
]);
const filterContacts = () => {
  if (!searchQuery.value) {
    return listContacts.value;
  }
  return listContacts.value.map((group) => {
    return {
      ...group,
      listData: group.listData.filter((item) =>
        item.fullName.toLowerCase().includes(searchQuery.value.toLowerCase())
      ),
    };
  });
};
watch([searchQuery, listContacts], () => {
  filteredListContactsFormat.value = filterContacts();
});
onMounted(async () => {
  dispatch('coordinator/getListContacts');
});
const handleClick = (type) => {
  if (type === 'contact') {
    labelSeclect.value = t('coordinator.contact');
    itemSelect.value = 'contact';
  } else {
    labelSeclect.value = t('coordinator.channel');
    itemSelect.value = 'channel';
  }
};
const handleClickContacts = (item) => {
  const index = listSelectContact.value.findIndex(
    (contact) => contact === item.userId
  );

  if (index !== -1) {
    listSelectContact.value.splice(index, 1);
    arraySelect.splice(index, 1);
  } else {
    let nameParts = item.fullName.split(' ');
    arraySelect.push(nameParts[nameParts.length - 1]);
    listSelectContact.value.push(item.userId);
  }
  searchQuery.value = arraySelect.join(',');
};

const handleClickChannle = (item) => {
  const index = listSelectChannel.value.findIndex(
    (channel) => channel === item.channelId
  );

  if (index !== -1) {
    listSelectChannel.value.splice(index, 1);
    arraySelect.splice(index, 1);
  } else {
    arraySelect.push(item.name);
    listSelectChannel.value.push(item.channelId);
  }
  searchQuery.value = arraySelect.join(',');
};

const handleShareMess = () => {
  let mess = {
    receiverIds: itemSelect.value === 'contact'?listSelectContact.value:listSelectChannel.value,
    receiverType: itemSelect.value === 'contact' ? 1 : 2,
    messageType: contentMessShare.value.messageType,
    mediaType: contentMessShare.value.mediaType,
    content: contentMessShare.value.content
      ? contentMessShare.value.content
      : ' ',
    url: contentMessShare.value.url,
    length: contentMessShare.value.length,
    forwarded: true,
    forwardFrom: contentMessShare.value.name,
  };
  dispatch('coordinator/shareMessages', mess);
};
</script>
<style lang="scss">
.input-search-share .ant-input-affix-wrapper > input.ant-input {
  background: #2B2A3A;
  color: #FFFFFF;
}
.input-search-share .ant-input-affix-wrapper > input.ant-input::placeholder {
  color: #F7F0F7;
}
</style>

<template>
  <div
    v-if="isShowModalConfirm"
    class="h-full w-full bg-[#000000B2] absolute flex items-center justify-center left-0 z-10"
  >
    <div
      class="min-h-[184px] w-[375px] bg-ems-gray700 rounded-2xl p-[16px] space-y-[16px]"
    >
      <div
        class="w-full flex items-center justify-end space-x-[8px] h-[25%] cursor-pointer"
      >
        <div
          v-for="(item, index) in listActions"
          :key="index"
          :class="`h-[32px] w-[32px] rounded-full flex items-center justify-center ${
            item.isSelect ? 'bg-white' : 'bg-ems-gray500'
          }`"
          @click="handleSelectItem(item.key)"
        >
          <img alt="icon" :src="item.icon" />
        </div>
      </div>
      <p class="text-white text-[12px] leading-[18px]">
        {{ t('coordinator.confirm_call') }}
      </p>
      <div class="flex items-center justify-center space-x-[32px]">
        <div
          class="cursor-pointer h-[45px] w-full bg-ems-main4 flex items-center justify-center rounded-[21px]"
          @click="handleJoinCall"
        >
          <p class="font-semibold text-[16px] text-white text-center">
            {{ t('coordinator.join_call') }}
          </p>
        </div>
        <div
          class="cursor-pointer h-[45px] w-full bg-ems-main3 flex items-center justify-center rounded-[21px]"
          @click="handleCancel"
        >
          <p class="font-semibold text-[16px] text-white text-center">
            {{ t('coordinator.cancel') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue';
import VueTypes from 'vue-types';
import { useI18n } from 'vue-i18n';
const emit = defineEmits(['handleJoinCall','handleSelectItem','handleCancel']);
const { t } = useI18n();
const props = defineProps({
  isShowModalConfirm: VueTypes.bool.def(false),
  listActions: VueTypes.array.def([]),
});
const handleJoinCall = (value) => {
  emit('handleJoinCall', value);
};
const handleSelectItem = (key) => {
  emit('handleSelectItem', key);
};
const handleCancel = () => {
  emit('handleCancel');
};
</script>
<style lang="scss">

</style>
